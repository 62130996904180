import http from '@/utils/httpRequest'

// 列表获取
export function getList (params) {
  return http({
    url: http.adornUrl('/common/projectorgpost/list'),
    method: 'get',
    params: params
  })
}

/** 查询所有信息列表 */
export function getAlllist(params){
  return http({
    url: http.adornUrl('/common/projectorgpost/alllist'),
    method: 'get',
    params: params
  })
}

// 删除数据
export function deleteData (ids = []) {
  return http({
    url: http.adornUrl("/common/projectorgpost/delete"),
    method: "post",
    data: http.adornData(ids, false)
  })
}

// 根据ID 获取
export function getById (id) {
  return http({
    url: http.adornUrl(`/common/projectorgpost/info/${id}`),
    method: "get"
  })
}

// 更新或新增
export function saveOrUpdate (data) {
  return http({
    url: http.adornUrl(
      `/common/projectorgpost/${!data.id ? "save" : "update"}`
    ),
    method: "post",
    data: data
  })
}