import http from '@/utils/httpRequest'


export function getList(name , creditCode , orgId , page = 1 , limit = 10) {
  	return http({
          url: http.adornUrl('/sys/department/list'),
          method: 'get',
          params: {name : name ,creditCode: creditCode ,orgId:orgId ,page:page , limit: limit}
        })
} 
export function deleteDept(ids = []) {
	return http({
            url: http.adornUrl("/sys/department/delete"),
            method: "post",
            data: http.adornData(ids, false)
          })
}
 

export function getById(id) {
	return http({
        url: http.adornUrl(`/sys/department/info/${id}`),
        method: "get"
        })
}
 
export function saveOrUpdate(data) {
	return http({
            url: http.adornUrl(
              `/sys/department/${!data.id ? "save" : "update"}`
            ),
            method: "post",
            data: data
        })
}
 
// 根据归属区域查询部门
export function listForOrgCode(id) {
  return http({
        url: http.adornUrl(`/sys/department/listForOrgCode`),
        method: "get",
        params: {orgCode: id}
        })
}
 

let depts = {}; 
 

//从local中获取当前已有的列表
var sessionOrgs_ = window.sessionStorage.getItem('depts');
if (sessionOrgs_ != null && sessionOrgs_ != '') {
  //console.log('sessionDicts_' , sessionDicts_)
  try {
    depts =  JSON.parse(sessionOrgs_);
   }catch(error) {
    depts = []
   }
 
}
 
function putOrg(id , name) {
 
  depts[id] = name 
  const json_ = JSON.stringify(depts)
  window.sessionStorage.setItem('depts' , json_);
 
}


/**
* 根据部门ID返回部门名称
* @param {id} 部门ID 
*/
export function getDeptName(id) {
 
  
  //判断缓存中是否存在
  return new Promise((resolve, reject)=>{

    if(!id) {
      reject()
      return
    }

    if(depts[id]) {
      resolve(depts[id])
      return 
    }

 
    getById(id).then(({data}) => {
           
      if (data.code === 0) {
        const info = data.data
        if(info) { 
          // 放入缓存 然后返回
          putOrg(id , info.name) 

          resolve(info.name)
          return
        }
      }  
      reject()
    }).catch((e)=>{
      reject()
    })

  })
   
}


export function clearDept() {

  depts = {}
  const json_1 = JSON.stringify(depts)
  window.sessionStorage.setItem('depts', json_1)
 
}