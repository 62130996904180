<template>
  <el-dialog
      :title="!dataForm.id ? $t('users.addUserInfo') : $t('users.updateUserInfo')"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      append-to-body
      :lock-scroll="false"
      :visible.sync="visible"
      width="65%" @close="closeDialog()">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" v-loading="isOnClick" label-width="110px">
      <el-form-item :label="$t('users.userNo')" prop="userNo">
        <el-input
            v-model.trim="dataForm.userNo" :placeholder="$t('users.userNo')"
            :disabled="dataForm.id != null && dataForm.id != '' && dataForm.id != '0' && dataForm.userNo != null" maxlength="30">
        </el-input>
        <span style="position: absolute; right: 10px; bottom: 0;">{{ dataForm.userNo ? dataForm.userNo.length : 0 }}/30</span>
      </el-form-item> 
      <el-form-item :label="$t('users.userName')" prop="username" >
        <el-input v-model.trim="dataForm.username" :placeholder="$t('users.userName')"></el-input>
      </el-form-item>
      <!-- <el-form-item :label="$t('users.sourceType')" prop="sourceType">
        <sysDictRadio v-model="dataForm.sourceType" dictType="source_type" @change="changeSourceType"/>
      </el-form-item>  -->
      <el-form-item label="主岗组织" prop="orgId">
        <el-org-select v-model="dataForm.orgId" placeholder="请选择组织" @change="orgIdChange" ></el-org-select>
        <!-- <el-cascader
            v-if="showCascader"
            v-model="dataForm.departmentIds"
            :options="orgOptions"
            :show-all-levels="true"
            clearable
            :props="{ checkStrictly: true, value:'id', label:'name', children:'children'}"
            filterable
            placeholder="请选择部门"
            style="width:100%"></el-cascader> -->
      </el-form-item>
      <el-form-item label="归属部门" prop="departmentIds">
        <el-dept-select v-model="dataForm.departmentIds" :orgId="dataForm.orgId" placeholder="请选择部门" @change="departmentIdsChange" ></el-dept-select>
        <!-- <el-cascader
            v-if="showCascader"
            v-model="dataForm.departmentIds"
            :options="orgOptions"
            :show-all-levels="true"
            clearable
            :props="{ checkStrictly: true, value:'id', label:'name', children:'children'}"
            filterable
            placeholder="请选择部门"
            style="width:100%"></el-cascader> -->
      </el-form-item>

<!--
      <el-form-item :label="$t('users.levelType')" prop="postId" v-if="dataForm.sourceType == 2">
        <el-select v-model="dataForm.postId" filterable placeholder="人员选择后带回" style="width:100%" disabled>
          <el-option
              v-for="item in postOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
-->      
      <!--      <el-form-item :label="$t('users.levelType')" prop="postId"   v-else>-->
      <!--        <el-select v-model="dataForm.postId" filterable placeholder="岗位，可输入关键字搜索" style="width:100%">-->
      <!--          <el-option-->
      <!--              v-for="item in postOptions"-->
      <!--              :key="item.id"-->
      <!--              :label="item.name"-->
      <!--              :value="item.id">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

<!--
      <el-form-item :label="$t('users.post')" prop="levelId" v-if="dataForm.sourceType == 2">
        <el-select v-model="dataForm.levelId" filterable placeholder="人员选择后带回" disabled style="width:100%">
          <el-option
              v-for="item in levelOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
-->      
      <!--      <el-form-item :label="$t('users.post')" prop="levelId"  v-else>-->
      <!--        <el-select v-model="dataForm.levelId"  placeholder="职务，可输入关键字搜索" style="width:100%">-->
      <!--          <el-option-->
      <!--              v-for="item in levelOptions"-->
      <!--              :key="item.id"-->
      <!--              :label="item.name"-->
      <!--              :value="item.id">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->


      <el-form-item :label="$t('users.type')" prop="type">
        <sysDictRadio v-model="dataForm.type" dictType="user_type" @change="changeType"/>
      </el-form-item>
      <el-form-item :label="$t('users.gender')">
        <sysDictRadio v-model="dataForm.sex" dictType="sex"/>
      </el-form-item>
      <el-alert
          v-if="!dataForm.id"
          title="默认初始密码为：gdyy1234"
          type="info"
          show-icon>
      </el-alert>
      <el-form-item v-if="!dataForm.id" :label="$t('users.pwd')" prop="password"
                    :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.password" type="password" :placeholder="$t('users.pwd')"></el-input>
      </el-form-item>
      <el-form-item v-if="!dataForm.id" :label="$t('users.comfirmPassword')" prop="comfirmPassword"
                    :class="{ 'is-required': !dataForm.id }">
        <el-input v-model="dataForm.comfirmPassword" type="password"
                  :placeholder="$t('users.comfirmPassword')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('users.phone')" prop="mobile">
        <el-input v-model="dataForm.mobile" :placeholder="$t('users.phone')"></el-input>
      </el-form-item>
      <!--      <el-form-item :label="$t('users.org')" prop="orgId" v-if="dataForm.type == 2">
              <sysOrg :form="dataForm" field="orgId" :defaultUserCode="true" :multiple="false" :lazy="true" @change="organizationForDp"></sysOrg>
            </el-form-item>
            <el-form-item :label="$t('users.dept')" prop="departId" v-if="dataForm.type == 2">
              <el-select v-model="dataForm.departId" :placeholder="$t('users.chooice')" clearable filterable>
                <el-option v-for="item in departList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>-->

      <!--      <el-form-item :label="$t('users.levelType')" prop="levelType" >
              <sysDictRadio v-model="dataForm.levelType" dictType="level_type" />
            </el-form-item>-->

      <el-form-item :label="$t('users.backRule')" size="mini" prop="roleIdList">
        <el-checkbox-group v-model="dataForm.roleIdList">
          <template v-for="role in roleList">
            <el-checkbox :key="role.roleId" v-if="role.type == dataForm.type" :label="role.roleId">{{
                role.roleName
              }}
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('users.status')" size="mini" prop="status">
        <sysDictRadio v-model="dataForm.status" dictType="user_status"/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnClick">{{ $t('confirm') }}</el-button>
    </span>
    <SelectDialog ref="selectDialog" v-if="selectVisible" @changeSelect="choiceMember"/>
  </el-dialog>
</template>

<script>
import {treeDataTranslate} from "@/utils";
import {encryptDes} from "@/utils/des";
import {validatorPassword} from '@/utils/validate'
import {getById, saveOrUpdate, getUserPhone} from '@/api/sys/user'
import {selectList} from '@/api/sys/role'
import {listForOrgCode} from '@/api/sys/dept' 
import {getList} from '@/api/common/projectorgmember.js'
import SelectDialog from "./select-dialog";
import ElDeptSelect from "@/components/dept-select";
import ElOrgSelect from "@/components/org-select";
import {getTree as unitDeptTree} from '@/api/sys/sysorg.js'; 

export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !validatorPassword(value)) {
        callback(this.$t('users.inputErrorMessage'))
      } else {
        callback()
      }
    }
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.dataForm.id && !validatorPassword(value)) {
        callback(this.$t('users.inputErrorMessage'))
      } else if (this.dataForm.password !== value) {
        callback(this.$t('users.consistentPwd'))
      } else {
        callback()
      }
    }
    return {
      selectVisible: false,
      visible: false,
      chooseVisible: false,
      dataListLoading: false,
      isOnClick: false,
      loadend: false,
      showCascader: true,
      orgOptions: [],
      roleList: [],
      departList: [],
      dataList: [],
      postOptions: [],
      levelOptions: [],
      dialogVisible: false,
      options: {
        checkedIds: [],
        title: '请选择部门' 
      },
      dataForm: {
        id: 0,
        username: "",
        password: "gdyy1234",
        comfirmPassword: "gdyy1234",
        userNo: "",
        sex: 1,
        type: 1,
        mobile: "",
        roleIdList: [],
        status: 1,
        departId: "",
        orgId: "",
        departmentIds: '',
        departmentId: "",
        postId: "",
        levelId: "",
        memberId: "",
        sourceType: "1"
      },
      searchForm: {
        name: ''
      },
      dataRule: {
        username: [
          {required: true, message: this.$t('validate.required'), trigger: "blur"}
        ],
        password: [{validator: validatePassword, trigger: "blur"}],
        comfirmPassword: [
          {validator: validateComfirmPassword, trigger: "blur"}
        ],
        userNo: [
          {required: true, message: this.$t('validate.required'), trigger: "blur"}
        ],
        type: [
          {required: true, message: this.$t('validate.required'), trigger: "blur"}
        ],
        orgId: [
          {required: true, message: this.$t('validate.required'), trigger: ['blur', 'change']}
        ],
        departId: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        sourceType: [
          {required: true, message: this.$t('validate.required'), trigger: ['blur', 'change']}
        ],
        departmentIds: [
          {required: true, message: this.$t('validate.required'), trigger: ['blur', 'change']}
        ],
        levelType: [
          {required: true, message: this.$t('validate.required'), trigger: ['blur', 'change']}
        ],
        postId: [
          {required: true, message: this.$t('validate.required'), trigger: ['blur', 'change']}
        ],
        levelId: [
          {required: true, message: this.$t('validate.required'), trigger: ['blur', 'change']}
        ],
      }
    };
  },
  components: {
    SelectDialog,
    ElDeptSelect,
    ElOrgSelect
  },
  methods: {
    departmentIdsChange(val) {
      console.log('onChange:' + val);
      this.dataForm.departmentIds = val;
      if(this.dataForm.departmentIds) {
        let departmentIds = this.dataForm.departmentIds.split(',');
        if(departmentIds && departmentIds.length > 0) {
          this.dataForm.departmentId = departmentIds[0];
        } 
      }
    },
    closeDialog(){
      this.$emit("destoryOrg")
    },
    orgIdChange(val) {
      this.dataForm.orgId = val;
    },
    init(id) { 
      this.dataForm.id = id || 0; 
      // this.unitDept()
      selectList().then(({data}) => {
        this.roleList = data && data.code === 0 ? data.data : [];
      })
          .then(() => {
            this.visible = true;
            this.$nextTick(() => {
              this.$refs["dataForm"].resetFields();
              if (this.dataForm.id) {
                this.dataForm.password = null;
              } else {
                this.dataForm.comfirmPassword = 'gdyy1234'
                this.dataForm.password = 'gdyy1234'
              }
            });
          })
          .then(() => {
            if (this.dataForm.id) {
              getById(this.dataForm.id).then(({data}) => {
                if (data && data.code === 0) {
                  let res = data.data
                  for (let item in this.dataForm) {

                    if (item == 'id') {
                      continue;
                    }

                    if (res[item] || res[item] === 0) {
                      if (item == 'password' || item == 'comfirmPassword') {
                        this.dataForm[item] = ''
                      } else if (item == 'roleIdList') {
                        this.dataForm[item] = res[item]
                      } else {
                        this.dataForm[item] = res[item] + ""
                      }
                    } else {
                      this.dataForm[item] = item == 'roleIdList' ? [] : ''
                    }
                  }
                  // this.dataForm.departmentIds = this.dataForm.departmentIds ? JSON.parse(this.dataForm.departmentIds) : []
                  // this.organizationForDp(data.data.orgId, 1)
                }
              });
            } else {
              // this.deptListTreeSetCurrentNode()
            }
          });
    }, 
    // 切换用户类型
    changeType(v) {
      this.dataForm.roleIdList = []
    },
    // 切换用户来源
    changeSourceType() {
      this.dataForm.username = ''
      this.dataForm.postId = ''
      this.dataForm.levelId = ''
    }, 
    // 获取部门
    organizationForDp(v, type) {
      if (type == 1) {
        this.loadend = true
      }
      if (type !== 1 && this.loadend && !this.loading) {
        this.dataForm.departId = ''
      }
      if (v) {
        listForOrgCode(v).then(({data}) => {
          if (data && data.code === 0) {
            this.departList = data.data
          }
        }).finally(() => {
        })
      } else {
        this.departList = []
      }
    },
 

    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          // getUserPhone(this.dataForm.mobile,this.dataForm.departmentIds).then(({data}) => {
          //   if (data != 0 ) {
          //     this.$message.error("手机号码重复！")
          //   }
          //   else {

              // 密码加密
              const n_pass = this.dataForm.password ? encryptDes(this.dataForm.password) : null;
              const data_ = JSON.parse(JSON.stringify(this.dataForm))
              data_.password = n_pass
              // data_.departmentId = data_.departmentIds && data_.departmentIds.length > 0 ? data_.departmentIds[data_.departmentIds.length - 1] : ''
              // data_.departmentIds = data_.departmentIds ? JSON.stringify(data_.departmentIds) : ''
              data_.userId = (this.dataForm.id == 0 ? null : this.dataForm.id)
              this.isOnClick = true;
              saveOrUpdate(data_).then(({data}) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: this.$t('prompt.success'),
                    type: "success"
                  });
                  this.isOnClick = false;
                  this.visible = false;
                  this.$emit("refreshDataList");
                } else {
                  this.$message.error(data.msg);
                }
              })
                  .finally(() => {
                    this.isOnClick = false;
                  });
          //   }
          // })
        }
      });
    },
    /** 打开选择用户页面 
    openChooseMember() {
      this.selectVisible = true;
      this.$nextTick(() => {
        this.$refs.selectDialog.openDialog();
      });
    },*/
    /** 选择用户并回填页面信息 
    choiceMember(row) {
      this.dataForm.postId = row.orgPostId
      this.dataForm.levelId = row.orgLevelId
      this.dataForm.memberId = row.id
      this.dataForm.username = row.name
      this.dataForm.userNo = row.loginName
      this.dataForm.departmentIds = row.orgAccountIds ? JSON.parse(row.orgAccountIds) : []
      this.chooseVisible = false
    }, */

    /**主岗单位和部门
    unitDept() {
      unitDeptTree().then(({data}) => {
        if (data && data.code === 0) {
          this.orgOptions = treeDataTranslate(data.data, "id", "orgAccountId");
        } else {
          console.error(`[${data.code}]${data.msg}`);
        }
      }).catch((err) => {
        console.log(err);
        this.$message.error(err)
      }).finally(_ => {
      })
      this.showCascader = false
      this.$nextTick(() => {
        this.showCascader = true
      })
    }
    */
  }
};
</script>
