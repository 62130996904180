<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="mainDialogVis"
        :close-on-click-modal="false"
        @closed="mainDialogClosed"
        class="main-dialog"
        width="80%"
        append-to-body>
      <el-container class="">
        <el-aside width="382px" style="overflow:scroll">
          <el-card header="权限设置" class="box-card" style="height: 100%">
            <div slot="header" class="clearfix">
              <span>组织机构</span>
            </div>
            <el-row>
              <el-col :span="10">
                <el-input placeholder="输入关键字进行过滤" v-model="filterText" style="width: 325px" clearable>
                </el-input>
              </el-col>
            </el-row>
            <el-row align="center" >
              <el-col :span="20">
                <el-switch
                    v-model="isVisDept"
                    active-text="不显示部门"
                    active-value="yes"
                    inactive-value="no"
                    @change="getDataListTree"
                    inactive-text="显示部门">
                </el-switch>
              </el-col>
            </el-row>
            <el-tree
                :data="dataListTree"
                :props="dataListTreeProps"
                node-key="id"
                ref="dataListTree"
                @current-change="dataListTreeCurrentChangeHandle"
                :default-expand-all="true"
                :filter-node-method="filterNode"
                :highlight-current="true"
                current-node-key="0"
                :expand-on-click-node="false">
            </el-tree>
          </el-card>
        </el-aside>
        <el-main id="reder-item-main" style="margin: 0px 0px 0px -20px">
          <div class="mod-config">
            <el-form :inline="true" :model="dataForm"  @keyup.enter.native="currentChangeHandle(1)"
                     @submit.native.prevent>
              <table class="detailTable_search" style="width: 100%">
                <tr>
                  <td class="titleTd width100">名称</td>
                  <td class="valueTd">
                    <el-input v-model="dataForm.name" placeholder="名称" clearable></el-input>
                  </td>
                  <td class="titleTd width100">岗位</td>
                  <td class="valueTd">
                    <el-select v-model="dataForm.orgPostId" filterable  @change="currentChangeHandle(1)" clearable  placeholder="主岗岗位，可输入关键字搜索" style="width:100%">
                      <el-option
                          v-for="item in postOptions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </td>
                  <td class="titleTd width100">政治面貌</td>
                  <td class="valueTd">
                    <sysDictSelect v-model="dataForm.politics" dictType="staff_politics" placeholder="请选择政治面貌"/>
                  </td>
                  <td class="titleTd width100">员工状态</td>
                  <td class="valueTd">
                    <sysDictSelect v-model="dataForm.state" dictType="staff_state" placeholder="请选择员工状态"/>
                  </td>
                </tr>
              </table>
            </el-form>
            <div class="wxts_msg_search">
              <div class="f_t">
                <el-button-group>
                  <el-button @click="currentChangeHandle(1)" :loading="dataListLoading">{{ $t('query') }}</el-button>
                </el-button-group>
              </div>
            </div>
            <el-table
                :data="dataListUser"
                border
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">

              <el-table-column prop="name" header-align="center" align="center" label="姓名"></el-table-column>
              <el-table-column prop="loginName" header-align="center" align="center" label="登录名"></el-table-column>
              <el-table-column
                  prop="orgAccountName"
                  header-align="center"
                  align="center"
                  label="组织机构"
                  min-width="80"/>
              <el-table-column prop="orgPostName" header-align="center" align="center" label="岗位" min-width="50%"></el-table-column>
              <el-table-column prop="orgPostName" header-align="center" align="center" label="职务" min-width="50%"></el-table-column>

              <el-table-column prop="politics" header-align="center" align="center" label="政治面貌">
                <template slot-scope="scope">
                  <sysDict type="staff_politics" :value="scope.row.politics"></sysDict>
                </template>
              </el-table-column>
              <el-table-column
                  prop="state"
                  header-align="center"
                  align="center"
                  label="员工状态"
                  width="90px">
                <template slot-scope="scope">
                  <sysDict type="staff_state" :value="scope.row.state"></sysDict>
                </template>
              </el-table-column>
              <el-table-column
                  prop="isEnable"
                  header-align="center"
                  align="center"
                  label="是否启用"
                  width="90px">
                <template slot-scope="scope">
                  <sysDict type="enable" :value="scope.row.isEnable"></sysDict>
                </template>
              </el-table-column>
              <el-table-column header-align="center" align="center" width="100" :label="$t('handle')">
                <template slot-scope="scope">
                  <el-button type="primary" @click="changeSelect(scope.row)">
                    带回
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :total="totalCount"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
          </div>
        </el-main>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import {treeDataTranslate} from "@/utils";
import {getAlllist} from '@/api/common/projectorgunit.js'
import {getList} from '@/api/common/projectorgmember.js'
import { getAlllist as getAllPostList } from '@/api/common/projectorgpost.js'

export default {
  data() {
    return {
      postOptions:[],
      expends: [],
      dataForm: {
        orgPostId:'',
        name: '',
        politics: '',
        state: ''


      },
      dataFormUnit: {
        name:'',
        shortName:'',
        status:'',
        type:'Account',
        orgAccountIds:'',

      },
      checked: [],
      checkedKeys: '',
      // dialog 数据
      dialogTitle: '选择数据',
      mainDialogVis: false,
      flag: '',
      api: null,
      dataListTreeProps: {
        label: "name",
        children: "children"
      },
      // 查询字段数据
      queryFields: {
        flowStatus: 'passed'
      },
      dataListTree: [],
      // 列表的数据
      dataList: [],
      dataListUser: [],
      dataListUnit:[],
      dataListLoading: true,
      filterText: "",
      outputPlanList:"",
      // 分页
      page: 1,
      pageSize: 10,
      totalCount: 0,
      projectId: '',
      orgid: [],
      pageIndex: 1,
      isVisDept:"yes"
    }
  },
  components: {},
  methods: {
    //设置table中的扩展项，展开的id，此处我需要全部展开
    getExpends() {
      var Id = this.dataList.map(item => item.id)
      this.expends = Id
    },
    getRowKeys(row) {
      return row.id
    },

    // 获取数据列表
    getDataListUser(data) {
      this.dataListLoading = true
      this.dataFormUnit.orgAccountIds = data ? data.id : this.dataFormUnit.orgAccountIds
      this.orgid = data && data.orgAccountIds ? JSON.parse(data.orgAccountIds) : [];
      this.orgid.push(this.dataFormUnit.orgAccountIds);
      this.getPostOptions()
      const params_ = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'name': this.dataForm.name,
        'politics': this.dataForm.politics,
        'state': this.dataForm.state,
        'orgAccountIds': this.dataFormUnit.orgAccountIds,
        'isEnable': '1',
        'orgPostId':this.dataForm.orgPostId
      }
      getList(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.dataListUser = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataListUser = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataListUser()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataListUser()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 菜单树选中
    dataListTreeCurrentChangeHandle(data, node) {
      //调用list页面方法
      this.pageIndex=1
      this.dataFormUnit.orgAccountIds=''
      this.dataForm.orgPostId=""
      this.$nextTick(() => {
        this.getDataListUser(data)
      })
      this.menuSelectShow = false;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getPostOptions(){
      const params_ = {
        'orgAccountId': this.dataFormUnit.orgAccountIds
      }
      getAllPostList(params_).then(({data}) => {
        if (data && data.code === 0) {
          this.postOptions = data.data
        }else{
          console.error(`[${data.code}]${data.msg}`);
        }
      }).catch((err) => {
        console.log(err);
        this.$message.error(err)
      }).finally(_ => {})
    },
    // 获取数据列表
    getDataListTree() {
      this.dataListLoading = true
      const params_ = {
        'isVisDept':this.isVisDept
      }
      getAlllist(params_).then(({data}) => {
        if (data && data.code === 0) {
          //this.dataList = data.data
          this.dataListTree = treeDataTranslate(data.data, "id", "orgAccountId");
          if (this.dataListTree[0]) {
            /*this.dataListTreeCurrentChangeHandle(this.dataList[0]);*/
          }
        } else {
          this.dataListTree = []
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    /**
     * 打开dialog选择数据回填值
     * 传入标志,根据标志选择使用哪一个查询内容去进行选择
     *
     * @param flag 使用那个组件的标志
     * @param api 使用那个接口进行查询数据
     */
    openDialog() {
      this.mainDialogVis = true
      this.getDataListTree();
      this.getDataListUser();

    },

    // dialog关闭回调
    mainDialogClosed() {
      this.dataList = []
    },


    // 选中元素
    changeSelect(row) {
      this.$emit('changeSelect', row)
      this.mainDialogVis = false
    },
  },
  watch: {
    filterText: {
      handler(val , oldVal){
        this.$refs.dataListTree.filter(val);
      },
      deep:true
    }
  },
  props: {
    record: {
      type: Object,
      default: () => {
      }
    }

  },
  mounted() {
    // 动态的加载组件

  },
}
</script>

<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
